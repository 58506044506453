$body: #F5F3F1;

$border-sm: 4px;
$border-norm: 10px;

$border-lg: 1px solid rgba(0, 0, 0, 0.12);
$border-dark: 1px solid rgba(0, 0, 0, 0.32);

$auth-wrapper: #fff;
$header-text: #dae2ea;
$page-wrap: #FFF8EF;


$link: #3597fa;

$dark-orange: #006FFF;
$code: #acacac;
$orange: #3597fa;
$orange-disabled: #8cc3fa;
$white: #ffffff;
$black: #000000;
$statusText: #1C1C1C;

$cur--hover: #f9f9f9;
$cur--select: #f9f9f9;

$grey-text: #797979;
$grey-lg-text: #727272;

$step-sub-header: #4f4f4f;

$cur-name: #707070;


$green: #4BBD5C;
$red: #e84c4c;
$grafit: #565656;
$yellow: #FFC200;
$blue: #006FFF;
$grey: #f5f5f5;