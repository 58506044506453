@import "@/assets/styles/styles.scss";














































































































.orderHeaderSection {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
}

.orderHeaderSection .textLine {
    display: flex;
    align-items: center;
}
.orderHeaderSection .orderId {
    font-size: 22px;
    font-weight: 600;
    margin-right: 8px;
}
.orderHeaderSection .orderLink {
    font-size: 14px;
    margin-right: 8px;
    color: #3597fa;
}

.orderLinkSection {
    margin-top: 8px;
}

.orderHeaderSection_timeSection {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-end;
}

.orderHeaderSection_timer {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.orderHeaderSection_dateCreated {
    font-size: 14px;
}
