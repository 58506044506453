@import "@/assets/styles/styles.scss";









































































































.preOrderAddQr {
}

.preOrderAddQr_content {
    font-size: 18px;
    line-height: 20px;
    color: $grafit;
}

.preOrderAddQr_desc {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $grafit;
    margin-top: 20px;
}

.preOrderAddQr_desc p {
    margin-bottom: 10px;
}
.preOrderAddQr_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.preOrderAddQr_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.preOrderAddQr_btnsWrap button:last-child {
    margin-left: 10px;
    display: inline-block;
}
