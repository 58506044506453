@import "@/assets/styles/styles.scss";



































































































































































.order-content {
    padding: 0px 16px 16px 16px;
}

.order-wrapper {
    min-width: 480px;
    max-width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.header > .spinner {
    height: 32px;
    width: 32px;
    margin-right: 16px;
}

.header-name {
    font-size: 24px;
    line-height: 30px;
    color: $grafit;
}

.icon-close {
    cursor: pointer;
    color: $black;
}

.border {
    height: 1px;
    width: 100%;
    background-color: $cur--select;
    margin: 16px 0;
}

.order-step:not(:last-child) {
    margin-bottom: 16px;
    color: $grafit;
}

.order-step .icon-step {
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    color: $white;
    background-color: $orange;
    border-radius: 50%;
    margin-right: 8px;
}

.status-description {
    font-size: 16px;
    line-height: 24px;
}

.status-description .address {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.status-description .address .label {
    margin-right: 4px;
}

.status-description .address a {
    color: #4a76a8;
}

.button-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.castom-btn {
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    background-color: #3597fa;
    padding: 10px 16px;
    margin: 0;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0 rgba(53, 151, 250, 0.24);
    text-decoration: none;
    border: none;
    position: relative;
    overflow: hidden;
    color: #fff;

    &:hover {
        background: $orange radial-gradient(circle, transparent 1%, $orange 1%) center/15000%;
    }
}

@media (max-width: 739px) {
    .order-wrapper {
        min-width: 0;
    }
    .order-exchange .icon-exchange {
        text-align: center;
    }
}

.direction-description {
    margin: 10px 0px;
    font-weight: 400;
    color: #2d3540;
}

.order-warapper {
    min-width: 500px;
}

.description-resolve {
    font-weight: 600;
    margin-top: 24px;
}

.field-resolve {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px;
    flex-wrap: wrap;
    padding: 5px 0px;
    border-radius: 6px;
    margin-bottom: 10px;
}

.resolve-info {
    margin: 20px 0px;
}

.field-resolve span {
    font-size: 1.1rem;
    margin-right: 10px;
}

.resolve-btn {
    padding: 5px 10px;
}

@media (max-width: 560px) {
    .field-resolve {
        margin-bottom: 0px;
    }
    .resolve-btn {
        margin-top: 10px;
    }
}

.resolve-desc {
    font-size: 1.1rem;
}
.step-content {
    font-size: 16px;
    line-height: 32px;
    background-color: $cur--hover;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: $border-sm;
    border: $border-lg;
}
.field-name {
    color: #2a5885;
    margin-right: 16px;
}
.field-value {
    font-size: 16px;
    word-break: break-word;
}
.item-rpp {
    display: flex;
}
.copy-text {
    margin-left: 1rem;
    color: #3597fa;
}
.castom-btn {
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    background-color: $orange;
    padding: 10px 16px;
    margin: 0;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0 rgba(53, 151, 250, 0.24);
    text-decoration: none;
    border: none;
    position: relative;
    overflow: hidden;
    color: #fff;
    &:hover {
        background: $orange radial-gradient(circle, transparent 1%, $orange 1%) center/15000%;
    }
}
.svg-icon-copy {
    margin-left: 1rem;
    width: 28px;
    height: 28px;
}
.copy-icon > path:hover {
    fill: #3597fa;
}

.open-chat {
    color: #2d3540;
    margin: 20px 0px 0px 0px;
}

.open-chat span {
    cursor: pointer;
    color: #2a5885;
    text-decoration: underline;
}

//  <div class="qrcode-section" v-if="getQrCode">
//         <p class="qrcode-section-title">QR-код для оплаты</p>
//         <img class="qrcode-section-image" :src="getQrCode" />

.qrcode-section {
    background-color: white;
    width: max-content;
    margin: 10px auto;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.qrcode-section-title {
    text-align: center;
    color: #2a5885;
    font-size: 20px;
}
.qrcode-section-image {
    display: block;
    margin: 10px auto;
}
