@import "@/assets/styles/styles.scss";





























































































































.viewStepperSection {
    padding: 24px 32px;
    padding-bottom: 42px;
}

.viewStepperSection .titleLine {
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewStepperSection .title {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
}

.viewStepperSection .subtitle {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
}

// .viewStepperSection .view {

// }
