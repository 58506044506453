@import "@/assets/styles/styles.scss";


















.cashback-description p {
    color: $grafit;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
}
