@import "@/assets/styles/styles.scss";
































.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: "Gotham Pro", serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 70vh;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: $white !important;
    padding: 10px 20px;
    margin: 20px 0;
    display: inline-block;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    background-color: $orange;
    border-radius: 4px;
    box-shadow: 2px 2px 6px 0 rgba(53, 151, 250, 0.24);
    text-decoration: none;
    border: none;
    position: relative;
    overflow: hidden;
}

.contant_box_404 {
    margin-top: -50px;
}

.contant_box_404 h3 {
    font-size: 32px;
    margin-bottom: 1rem;
    color: $grafit;
}

.contant_box_404 p {
    color: $red;
    font-size: 16px;
}

.text-center {
    text-align: center;
}
