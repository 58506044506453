@import "@/assets/styles/styles.scss";





























































.bannedCardSection {
    font-size: 16px;
    line-height: 22px;
    color: #252525;
}
