@import "@/assets/styles/styles.scss";

































































.news-item {
    position: relative;
    padding: 12px 16px;
}

.news-item:not(.current):hover:after,
.news-item.current:after {
    height: 100%;
    width: 2px;
    display: block;
    content: " ";
    top: 0;
    right: -18px;
    position: absolute;
    background-color: $orange;
}

.news-item:not(:last-child) {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.16);
}

.title {
    cursor: pointer;
    color: $grey-text;
    font-size: 16px;
    line-height: 22px;
    text-decoration: underline;
    margin-bottom: 8px;
}

.news-item.current .title {
    color: $orange;
}

.date {
    color: $grey-lg-text;
    font-size: 14px;
}

@media (max-width: 739px) {
    .news-item {
        padding: 12px 0;
    }
}
