@import "@/assets/styles/styles.scss";


















.orderPaymentLinks {
}
.orderPaymentLinks_item {
    margin-bottom: 10px;
}
.orderPaymentLinks_item a {
    cursor: pointer;
    color: #3597fa;
    text-transform: uppercase;
}
.orderPaymentLinks_item a:hover {
    text-decoration: underline;
}
