@import "@/assets/styles/styles.scss";





















































































.order-wrapper {
    min-width: 480px;
    max-width: 100%;
}
