<!--<template>-->
<!--    <transition name="loading" appear>-->
<!--        <div class="loading">-->
<!--            <div class="loader"></div>-->
<!--            <div id="app" class="wrapper" ref="loadprogresser">-->
<!--                <div class="percent">{{ TextPrc }}</div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </transition>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--    data() {-->
<!--        return {-->
<!--            d: "M 0 0",-->
<!--            Samples: 0, //номер текущего сэмпла-->
<!--            maxSamples: 400, //кол-во сэмплов в 100%-ах ширины-->
<!--            wrapHeight: 100, //высота в которую надо вписать изображение-->
<!--            maxY: 0,-->
<!--            scales: {-->
<!--                w: 1,-->
<!--                h: 1-->
<!--            },-->
<!--            timePoints: [],-->
<!--            TimeM: 100,-->
<!--            transform: "scale( 1, -1) translate(0,0) rotate(0)",-->
<!--            TextPrc: "0%"-->
<!--        };-->
<!--    },-->
<!--    mounted: function() {-->
<!--        this.getScales();-->
<!--        this.update();-->
<!--    },-->
<!--    methods: {-->
<!--        getScales() {-->
<!--            const { width, height } = this.$refs.loadprogresser.getBoundingClientRect();-->
<!--            this.scales.w = width / this.maxSamples;-->
<!--            this.wrapHeight = height;-->
<!--            this.transform = `scale( 1, -1) translate(0,${-this.wrapHeight})`;-->
<!--        },-->
<!--        getVScale() {-->
<!--            //шкала по вертикали-->
<!--            this.scales.h = this.maxY === 0 ? 0 : this.wrapHeight / this.maxY;-->
<!--        },-->
<!--        //передаю значения выделяю максимум и выдаю шкалу относительно этого максимума-->
<!--        getYMax({ value = 0 }) {-->
<!--            if (value > this.maxY) this.maxY = value;-->
<!--        },-->
<!--        addTime({ time = 0 }) {-->
<!--            //добавление данных, а так как я добавляю-->
<!--            //время выполнения операции то и метод назван addTime-->
<!--            if (this.Samples < this.maxSamples) {-->
<!--                this.getYMax({-->
<!--                    value: time-->
<!--                });-->
<!--                this.getVScale();-->
<!--                this.timePoints.push(time); //интересуют Int-->
<!--                this.Samples++;-->
<!--                this.TextPrc = `${((this.Samples * 100) / this.maxSamples) | 0} %`;-->
<!--            }-->
<!--        },-->
<!--        getSVGTimePoints() {-->
<!--            //теперь создаю строку для Path-->
<!--            let predY = 0;-->
<!--            let path = this.timePoints.reduce((str, item) => {-->
<!--                //тег "l" означает что в координатах передаётся приращение-->
<!--                //к предыдущей точке, а не абсолютные координаты-->
<!--                let dY = (item - predY) * this.scales.h;-->
<!--                predY = item;-->
<!--                return str + `l ${this.scales.w} ${dY} `;-->
<!--            }, "M 0 0 ");-->
<!--            path += `L ${this.timePoints.length * this.scales.w} 0`; // Z` контур можно не замыкать-->
<!--            return path;-->
<!--        },-->
<!--        update() {-->
<!--            //постепенно увеличиваю диапазон случайного числа-->
<!--            //чтобы отработать вертикальное масштабирование-->
<!--            this.addTime({-->
<!--                time: (this.TimeM * Math.random()) | 0-->
<!--            });-->
<!--            this.TimeM *= 1.01;-->
<!--            this.d = this.getSVGTimePoints();-->
<!--            setTimeout(this.update, 7);-->
<!--        }-->
<!--    }-->
<!--};-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--.loading {-->
<!--    position: fixed;-->
<!--    background: #fff8ef;-->
<!--    z-index: 999;-->
<!--    top: 50%;-->
<!--    left: 50%;-->
<!--    overflow: hidden;-->
<!--    transform: translate(-50%, -50%);-->
<!--    width: -webkit-fill-available;-->
<!--    height: -webkit-fill-available;-->
<!--}-->

<!--.loader {-->
<!--    border: 14px solid #eee8e1; /* Light grey */-->
<!--    border-top: 14px solid $orange; /* Blue */-->
<!--    border-radius: 50%;-->
<!--    width: 120px;-->
<!--    height: 120px;-->
<!--    animation: spin 1.8s linear infinite;-->
<!--    position: fixed;-->
<!--    top: calc(50% - 60px);-->
<!--    left: calc(50% - 60px);-->
<!--    z-index: 1000;-->
<!--    overflow: hidden;-->
<!--    transform: translate(-50%, -50%);-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12%), 0 0 20px 0 rgba(0, 0, 0, 0.12%), 0 0 20px 0 rgba(0, 0, 0, 0.12%);-->
<!--}-->

<!--.percent {-->
<!--    position: fixed;-->
<!--    top: 50%;-->
<!--    left: 50%;-->
<!--    z-index: 1000;-->
<!--    overflow: hidden;-->
<!--    transform: translate(-50%, -50%);-->
<!--    display: flex;-->
<!--    justify-content: center;-->
<!--    font-size: 20px;-->
<!--    font-weight: 500;-->
<!--}-->

<!--@keyframes spin {-->
<!--    0% {-->
<!--        transform: rotate(0deg);-->
<!--    }-->
<!--    100% {-->
<!--        transform: rotate(360deg);-->
<!--    }-->
<!--}-->

<!--@media (max-width: 739px) {-->
<!--    .loader {-->
<!--        border: 10px solid #dddddd;-->
<!--        border-top: 10px solid $orange;-->
<!--        border-radius: 50%;-->
<!--        width: 120px;-->
<!--        height: 120px;-->
<!--        animation: spin 1.8s linear infinite;-->
<!--    }-->
<!--}-->
<!--</style>-->

<template>
    <transition name="loading" appear>
        <div class="loading">
            <div class="loading-track"></div>
        </div>
    </transition>
</template>

<style scoped>
.loading {
    width: 100%;
    height: 4px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.loading .loading-track {
    right: 0;
}

.loading .loading-track:after,
.loading .loading-track:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    will-change: left, right;
    content: "";
}

.loading .loading-track:before {
    animation: progress-indeterminate 2.3s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.loading .loading-track:after {
    animation: progress-indeterminate-short 2.3s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
}

.loading.loading-enter,
.loading.loading-leave-active {
    opacity: 0;
    transform: scaleY(0) translateZ(0);
}

.loading.loading-enter-active {
    transform: scaleY(1) translateZ(0);
}

.loading-track {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@keyframes progress-indeterminate {
    0% {
        right: 100%;
        left: -35%;
    }
    60% {
        right: -100%;
        left: 100%;
    }
    to {
        right: -100%;
        left: 100%;
    }
}

@keyframes progress-indeterminate-short {
    0% {
        right: 100%;
        left: -200%;
    }
    60% {
        right: -8%;
        left: 107%;
    }
    to {
        right: -8%;
        left: 107%;
    }
}

.loading .loading-track:after,
.loading .loading-track:before {
    background-color: #3597fa;
}
</style>
