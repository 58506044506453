@import "@/assets/styles/styles.scss";

















































.news {
    color: $grey-lg-text;
    font-size: 16px;
}

.news .page-name {
    text-align: center;
    font-size: 32px;
    margin: 32px 0;
}

.news .card {
    background-color: $white;
    margin: 16px;
    padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.news .card > .cell:nth-child(1) {
    padding-right: 16px;
    box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.16);
}

.news .card > .cell:nth-child(2) {
    padding-left: 32px;
}

.news .card > .cell > .title {
    color: $grafit;
    font-size: 26px;
    margin-bottom: 24px;
}

.news .card > .cell > .body {
    line-height: 24px;
}

.news .card > .cell > .body > p {
    color: $grafit !important;
}

.news .card > .cell > .body > img {
    width: 100% !important;
    height: auto;
}

.news .card > .cell > .body > a {
    color: #004dff !important;
}

@media (max-width: 739px) {
    .news .card {
        margin: 8px;
        padding: 24px 16px;
    }
    .news .card > .cell:nth-child(2) {
        padding-left: 0;
    }
    .news .card .list-news {
        margin-bottom: 24px;
    }
}
