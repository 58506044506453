@import "@/assets/styles/styles.scss";






































.accrual-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grafit;
}

.amount {
    font-size: 16px;
    font-weight: 500;
}

.amount > .code {
    font-weight: 400;
}

.pair {
    display: flex;
}
