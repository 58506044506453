@import "@/assets/styles/styles.scss";










































































































.imageSelect {
}

.imageSelect_addWrap {
    margin: 16px 0;
}

.imageSelect_addWrap .file {
    display: none;
}

.imageSelect #image {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.imageSelect #image + label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $grafit;
    padding: 10px 16px 14px 16px;
    border: 1px solid $orange;
    border-radius: 8px;
}

.imageSelect #image:focus + label,
#image + label:hover {
    color: $orange;
}
.imageSelect_imageWrap {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
}

.imageSelect_image {
    max-width: 100%;
    max-height: 200px;
}

.imageSelect_imageRemove {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 24px;
    color: #2a5885;
    background-color: $orange;
    border: 1.5px solid $grafit;
    border-radius: 50%;
}
