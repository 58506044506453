@import "@/assets/styles/styles.scss";






























































.referral-section {
    .referral-section-desc p {
        color: $grafit;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 16px; }
    .referral-section-copyboard {
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px !important;
        border: 1px solid #ebeff6;
        border-radius: $border-sm;
        .copyboard-title {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px; }
        .copyboard-zone {
            color: $black;
            font-size: 16px;
            font-weight: 400;
            background-color: #ebeff6;
            padding: 12px 16px;
            margin-bottom: 16px;
            border-radius: $border-sm;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                width: 25px;
                margin-left: 10px;
                cursor: pointer;
                transition: all 0.2s;
                &:hover {
                    opacity: 0.7; } } } } }
