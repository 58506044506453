@import "@/assets/styles/styles.scss";
























































.order-exchange .time {
    font-size: 14px;
}
.order-exchange .grid {
    width: 100%;
    align-items: center;
}
.order-exchange .grid > div {
    margin: 16px 0px;
}
.order-exchange .currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.order-exchange .currency .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    -o-object-fit: cover;
    object-fit: cover;
}
.order-exchange .currency .amount {
    font-size: 20px;
    margin-bottom: 8px;
}
.order-exchange .currency .name {
    color: $grafit;
    font-size: 16px;
}
.order-exchange .icon-exchange {
    color: #acacac;
}
.change-arrow {
    transform: rotate(-90deg);
}
