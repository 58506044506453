@import "@/assets/styles/styles.scss";

























































.discount-page {
    background-color: $white;
    margin: 16px;
    padding: 16px 8px;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.discount-page .title {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 8px;
}

.discount-page .not-verified {
    color: $grafit;
    font-size: 16px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.discount-page .not-verified .user-name {
    font-size: 18px;
}
.discount-page .content {
    margin: 0 16px 24px 16px;
}
