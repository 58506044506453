@import "@/assets/styles/styles.scss";





























































































.reward-card {
    display: flex;
    min-width: calc(33.333333% - 10px);
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 5px 5px 15px;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reward-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.reward-flex-bottom {
    margin-bottom: 0px;
    margin-top: 10px;
    color: #2d3540;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .reward-card {
        min-width: calc(50% - 10px);
    }
}

@media (max-width: 800px) {
    .reward-card {
        min-width: calc(100% - 10px);
    }
}

.reward-field {
    margin-bottom: 6px;
}

.reward-field span {
    color: #2d3540;
}

.reward-sum {
    color: #2d3540;
    font-size: 18px;
    font-weight: 500;
}

.reward-status {
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
}

.new {
    background-color: #ffc200;
}
.resolve {
    background-color: #4bbd5c;
}

.reject {
    background-color: #d0021b;
}

.reward-comment {
    margin-top: 10px;
}

.reward-comment span {
    color: red;
    font-size: 1.2rem;
    display: block;
    margin-top: 6px;
}
