
/* styles.css */

body {
  color: base-content;
}

.card {
  @apply bg-white rounded-xl shadow-card py-4 px-6;
}

.btn {
  transition: 0.4s all;
  @apply text-neutral bg-base-100 text-sm px-5 font-normal py-3 rounded-md hover:bg-base-200;
}

.btn-primary {
  @apply bg-primary text-white;
}

.input {
  @apply bg-base-100 rounded-xs border-2  px-4 py-2 text-neutral-content placeholder-base-100 border-transparent outline-none text-sm focus:bg-secondary ;
}

.input-error {
  @apply border-error;
  /* @apply focus:ring-2 focus:ring-error; */
}

.label-text {
  @apply text-sm text-neutral-content;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
