@import "@/assets/styles/styles.scss";




























@keyframes pulseShadow {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    10% {
        transform: scale(1.02);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    }
    20% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
}

.news-header {
    animation: pulseShadow 10s ease-in-out infinite;
}
