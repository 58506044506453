@import "@/assets/styles/styles.scss";

























.cardStep_icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    color: $white;
    background-color: $orange;
    border-radius: 50%;
    margin-right: 8px;
}
