@import "@/assets/styles/styles.scss";









































































































































.rejectedOrderCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.rejectedOrderCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}

.rejectedOrderCardSection_btnsWrap button:last-child {
    margin-left: 10px;
}
