@import "@/assets/styles/styles.scss";























































































































































































































































.header-wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    margin: 0 auto;
    // height: 64px;
    z-index: 998;
    background-color: $white;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
    transition: height 0.35s;
}

.header-wrap.is-profile {
    // height: 128px;
}

.navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    // height: 64px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
    overflow: hidden;
}

.navigation-profile-wrap {
    width: 100%;
    height: 64px;
    background-color: #ffffff;
    box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.32);
}

.navigation-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
}

.navigation .logo {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
    color: $grey-lg-text;
    font-family: Whitney, Proxima Nova, sans-serif, -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-right: 32px;
}

.navigation-item,
.navigation-profile-item {
    font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    position: relative;
    display: inline;
    text-align: center;
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    color: $grey-lg-text;
    margin: 0 16px;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.navigation-item:hover:after,
.navigation-profile-item:hover:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-item.router-link-active,
.navigation-profile-item.router-link-active {
    color: $orange;
}

.navigation-item.router-link-active:after,
.navigation-profile-item.router-link-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-profile-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    color: $grey-lg-text;
    line-height: inherit;
    padding-right: 2px;
}

.navigation-profile-item .icon {
    margin-right: 16px;
}

.navigation-sign {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    color: $grafit;
    border-radius: 4px;
}

.navigation-sign .icon {
    margin-right: 12px;
}

.navigation-user {
    cursor: pointer;
    position: relative;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
}

.navigation-user:hover:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.slang {
    margin: 0 0.5rem;
    font-size: 14px;
    font-weight: 400;
}

.flex {
    display: flex;
    text-align: end;
    align-items: center;
}

.sign-name {
    font-weight: 600;
}

.registr {
    margin-bottom: 1rem;
}

.navigation-user.router-link-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-user .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #565656;
    margin-right: 16px;
    border-radius: 50%;
}

.navigation-user .user-name {
    color: $grafit;
    font-weight: 500;
}

/* Mobile styles */
.navigation .icon-hamburger {
    cursor: pointer;
    color: $grey-lg-text;
}

.navigation-mobile {
    width: 100%;
    height: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 64px;
    left: 0;
    background-color: #ffffff;
    padding: 0;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32), 0 -2px 2px -2px rgba(0, 0, 0, 0.32);
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 2.2s;
    z-index: 999;
}

.navigation-mobile .navigation-sign {
    flex-direction: row;
    margin: 8px 0 24px 0;
}

.navigation-mobile .navigation-user {
    margin: 0 16px 24px 16px;
}
