@import "@/assets/styles/styles.scss";
























.qiwiChoosePayment {
    margin: 15px 0px;
}
.qiwiChoosePayment_title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
}
.qiwiChoosePayment_selectWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.qiwiChoosePayment_linkPay {
    color: #3597fa;
    cursor: pointer;
    margin-right: 5px;
}

.qiwiChoosePayment_direct {
    color: #3597fa;
    cursor: pointer;
    margin-left: 5px;
}

.qiwiChoosePayment_linkPay:hover,
.qiwiChoosePayment_direct:hover {
    text-decoration: underline;
}
